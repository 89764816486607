import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25-editable/s25.editable.abstract";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { EventService } from "../../services/event.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";

@TypeManagerDecorator("s25-ng-editable-additional-orgs")
@Component({
    selector: "s25-ng-editable-additional-orgs",
    template: ` @if (init) {
        <div class="c-eventDetails--additionalOrgs" aria-label="Additional Organizations" tabindex="0">
            <span class="evdd-item-label">Additional Organization{{ addtlOrgs.length === 1 ? ":" : "s:" }}</span>
            <div>
                @for (org of addtlOrgs; track org; let i = $index) {
                    <div
                        class="addtl-org-{{ i + 1 }} s25-item-org-container"
                        (mouseover)="handleRemoveButton(i + 1, $event)"
                        (mouseleave)="handleRemoveButton(i + 1, $event)"
                        (focusin)="handleRemoveButton(i + 1, $event)"
                        (focusout)="handleRemoveButton(i + 1, $event)"
                    >
                        <s25-item-organization [modelBean]="org" [includeTypeIcon]="true"></s25-item-organization>
                        @if (editable) {
                            <button (click)="removeOrg(org.itemId)" class="aw-button aw-button--danger--transparent">
                                Remove
                            </button>
                        }
                    </div>
                }
                @if (editable) {
                    <div>
                        <s25-ng-multiselect-search-criteria
                            [type]="'organizations'"
                            [popoverOnBody]="true"
                            [selectedItems]="addtlOrgs"
                            [(modelBean)]="multiSelectBean"
                            [customPopoverClass]="'additional-org-popover'"
                        ></s25-ng-multiselect-search-criteria>
                    </div>
                }
            </div>
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableAdditionalOrgsComponent extends S25EditableAbstract implements OnInit {
    @Input() itemId: string;
    @Input() addtlOrgs: S25ItemI[];
    @Input() editable: boolean;

    init: boolean = false;
    multiSelectBean: MultiselectModelI = {};

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
        this.elementRef.nativeElement.angBridge = this;
    }

    getType = () => "additional orgs";

    ngOnInit() {
        super.ngOnInit();

        this.sortOrgsAlphabetically();

        this.zone.run(() => {
            this.multiSelectBean.placeholder = "Organization Name";
            this.multiSelectBean.items = this.addtlOrgs;
            this.multiSelectBean.onDone = () => {
                const eventId = S25Util.toInt(this.itemId);
                const checkedOrgIds = this.multiSelectBean.addedItems
                    ? this.multiSelectBean.addedItems.map((org) => org.itemId)
                    : [];
                const uncheckedOrgIds = this.multiSelectBean.removedItems
                    ? this.multiSelectBean.removedItems.map((org) => org.itemId)
                    : [];

                return EventService.updateOrganizations([eventId], checkedOrgIds, uncheckedOrgIds).then(() => {
                    this.sortOrgsAlphabetically();

                    this.cd.detectChanges();
                });
            };
        });

        this.init = true;
        this.cd.detectChanges();
    }

    handleRemoveButton(index: number, event: Event) {
        if (!this.editable) return; // If not editable we can't delete
        const hoverEl = this.elementRef.nativeElement.querySelector(`.addtl-org-${index}`).children;

        if (event.type === "mouseover" || event.type === "focusin") {
            hoverEl[1].classList.add("delete-true");
        } else if (event.type === "mouseleave" || event.type === "focusout") {
            hoverEl[1].classList.remove("delete-true");
        }
    }

    removeOrg(orgId: number) {
        const eventId = S25Util.toInt(this.itemId);

        return EventService.updateOrganizations([eventId], [], [orgId]).then(() => {
            this.addtlOrgs = this.addtlOrgs.filter((el: S25ItemI) => el.itemId !== orgId);
            this.multiSelectBean.selectedItems = this.addtlOrgs;

            this.cd.detectChanges();
        });
    }

    sortOrgsAlphabetically() {
        if (this.addtlOrgs.length > 0) {
            this.addtlOrgs = this.addtlOrgs.sort((a, b) => {
                if (a.itemName > b.itemName) return 1;
                if (a.itemName < b.itemName) return -1;

                return 0;
            });
        }
    }
}
