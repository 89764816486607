import { NgModule } from "@angular/core";
import { EventFormPreviewComponent } from "./event.form.preview.component";
import { S25LinkParserComponent } from "../../standalone/s25.link.parser.component";
import { S25PipesModule } from "../../pipes/s25.pipes.module";

@NgModule({
    declarations: [EventFormPreviewComponent],
    imports: [S25LinkParserComponent, S25PipesModule],
    exports: [EventFormPreviewComponent],
    providers: [EventFormPreviewComponent],
})
export class EventFormModule {
    constructor() {}
}
