import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { EventService } from "../../services/event.service";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25EditableAbstract } from "../s25-editable/s25.editable.abstract";
import { S25LoadingApi } from "../s25-loading/loading.api";

export interface requirement extends DropDownItem {
    hasQuantity: boolean;
    quantity: number;
    hasComment: boolean;
    comment: string;
}

@TypeManagerDecorator("s25-ng-ev-req")
@Component({
    selector: "s25-ng-ev-req",
    template: `@if (this.init) {
        <div class="evdd-req--flex-col">
            @if (this.addReqButton && !this.readOnly) {
                <button class="aw-button aw-button--primary c-margin-top--quarter" (click)="this.showDropdown()">
                    Add a Requirement
                </button>
            }
            @if (!this.addReqButton) {
                <s25-ng-dropdown-search-criteria
                    [type]="'eventRequirements'"
                    [(chosen)]="this.chosenReq"
                    (chosenChange)="onChosen()"
                    class="ngEditableDropdown"
                ></s25-ng-dropdown-search-criteria>
            }
            <s25-loading-inline model="{}"></s25-loading-inline>
            @for (item of items; track item) {
                <div class="{{ !this.addReqButton ? 'evdd-req--hasDropdown' : '' }} c-margin-left--half">
                    <div class="evdd-req--flex c-margin-top--half">
                        <!--                <div class="ngBold c-margin-right&#45;&#45;half evdd-req&#45;&#45;delete" id="requirement_delete_{{item.itemName}}" *ngIf="!this.readOnly && this.showRemove && item.hasRemove" (click)="this.remove(item)" (keyup.enter)="this.remove(item)">-->
                        @if (!this.readOnly && this.showRemove) {
                            <div
                                class="ngBold c-margin-right--half evdd-req--delete"
                                id="requirement_delete_{{ item.itemName }}"
                                (click)="this.remove(item)"
                                (keyup.enter)="this.remove(item)"
                            >
                                <svg class="c-svgIcon">
                                    <title>Delete</title>
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                    />
                                </svg>
                            </div>
                        }
                        <div class="ngBlock evdd-req--name">
                            <s25-ng-link-parser [text]="item.itemName" />
                        </div>
                    </div>
                    @if (this.showComment && item.hasComment) {
                        <div class="c-margin-right--single c-margin-left--half ngInlineBlock">
                            @if (this.showLabels) {
                                Comment
                            }
                            <s25-ng-editable-textarea
                                [(val)]="item.comment"
                                [alwaysEditing]="this.alwaysEditing"
                                [hasCommit]="!this.alwaysEditing"
                                [onCommit]="item.saveComment"
                                [readOnly]="this.readOnly"
                                (valChange)="onItemsChange()"
                            >
                            </s25-ng-editable-textarea>
                        </div>
                    }
                    @if (this.showQuantity && item.hasQuantity) {
                        <div class="ngInlineBlock c-margin-left--half evdd-req--quantity">
                            @if (this.showLabels) {
                                Quantity
                            }
                            <s25-ng-editable-number
                                [type]="'integer'"
                                [(val)]="item.quantity"
                                [alwaysEditing]="this.alwaysEditing"
                                [hasCommit]="!this.alwaysEditing"
                                [onCommit]="item.saveQuantity"
                                [readOnly]="this.readOnly"
                                [min]="0"
                                (valChange)="onItemsChange()"
                            >
                            </s25-ng-editable-number>
                        </div>
                    }
                </div>
            }
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EvRequirementsComponent extends S25EditableAbstract implements OnInit {
    @Input() itemId: number;
    @Input() items: requirement[] = []; //requirements

    @Input() showDetails: boolean = true;

    @Input() showRemove: boolean = true;
    @Input() showComment: boolean = true;
    @Input() showQuantity: boolean = true;
    @Input() showLabels: boolean = true;
    @Input() addReqButton: boolean = true;
    @Input() updateCallback: (items: any) => void;
    init = false;
    chosenReq: any = null;

    @Output() itemsChange = new EventEmitter<any>();

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
        this.elementRef.nativeElement.angBridge = this;
    }

    getType = () => "eventRequirements";

    onItemsChange() {
        this.itemsChange.emit(this.items);
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.items = S25Util.array.forceArray(this.items);
        this.getFields(this.items).then((resp) => {
            this.items.forEach((item) => {
                item.saveComment = (comment: string) => {
                    return this.save(item.itemId, comment, "comment");
                };
                item.saveQuantity = (quantity: string) => {
                    return this.save(item.itemId, quantity, "quantity");
                };
            });

            this.init = true;
            this.cd.detectChanges();
        });
    }

    updateView() {
        this.cd.detectChanges();
    }

    onChosen() {
        let chosen = S25Util.deepCopy(this.chosenReq);
        this.chosenReq = {};
        this.add(chosen);
    }

    save(itemId: number | string, data: any, dataType: "quantity" | "comment") {
        if (dataType === "comment") data = data || "";
        if (dataType === "quantity") data = S25Util.toInt(data);
        let payload = {
            itemId: itemId,
            [dataType]: data,
        };
        return EventService.updateRequirements(this.itemId, payload, "update").then(
            (resp) => {
                return resp;
            },
            (err) => {
                S25Util.showError(err);
            },
        );
    }

    add(item: any) {
        if (!S25Util.array.isIn(this.items, "itemId", item.itemId)) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            return this.getFields(item).then((fields) => {
                item.saveComment = (comment: string) => {
                    return this.save(item.itemId, comment, "comment");
                };
                item.saveQuantity = (quantity: string) => {
                    return this.save(item.itemId, quantity, "quantity");
                };
                let promise = !this.alwaysEditing && EventService.updateRequirements(this.itemId, item, "add");
                return jSith.resolve(promise).then(
                    (resp) => {
                        this.items.push(item);
                        this.addReqButton = !this.alwaysEditing && true;
                        this.cd.detectChanges();
                        this.updateCallback && this.updateCallback(this.items);
                        this.onItemsChange();
                        S25LoadingApi.destroy(this.elementRef.nativeElement);
                    },
                    (err) => {
                        S25LoadingApi.destroy(this.elementRef.nativeElement);
                        S25Util.showError(err);
                    },
                );
            });
        }
    }

    /*
    Determines whether quantity/comments should be displayed for each attribute.
     */
    getFields(items: any) {
        return SearchCriteriaService.getEventRequirements(true, null, null).then((resp) => {
            items = S25Util.array.forceArray(items);
            items.forEach((item: any) => {
                let req = S25Util.array.getByProp(resp, "itemId", item.itemId);
                // item.hasRemove = !!req;
                item.hasQuantity = this.readOnly ? item.quantity > 0 : req?.quantity > -1;
                item.hasComment = !!req?.allowComment;
            });
            return items;
        });
    }

    remove(item: any) {
        let promise = !this.alwaysEditing && EventService.updateRequirements(this.itemId, item, "remove");
        S25LoadingApi.init(this.elementRef.nativeElement);
        jSith.resolve(promise).then(
            (resp) => {
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                S25Util.array.inplaceRemoveByProp(this.items, "itemId", item.itemId);
                this.updateCallback && this.updateCallback(this.items);
                this.cd.detectChanges();
            },
            (err) => S25Util.showError(err),
        );
    }

    showDropdown() {
        this.addReqButton = false;
        this.cd.detectChanges();
    }
}
